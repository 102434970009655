<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      Pure water has a boiling point of 100.0°C and has a boiling point elevation constant K<sub
        >b</sub
      >
      of 0.512°C/m.
    </p>
    <p>
      a) Assuming an ideal solution and no evaporation, calculate the change in boiling point of the
      solution when a non-volatile solute is dissolved in water yielding a solution with a molality
      of
      <number-value :value="molality" />molal.
    </p>
    <calculation-input
      v-model="inputs.changeInBoilingPoint"
      prepend-text="$\Delta\text{T}_\text{b}:$"
      append-text="$^\circ\text{C}$"
    />
    <br />
    <p>b) Calculate the boiling point of the solution.</p>
    <calculation-input
      v-model="inputs.boilingPoint"
      append-text="$^\circ\text{C}$"
      prepend-text="$\text{T}_\text{b}:$"
    />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import NumberValue from '@/tasks/components/NumberValue.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'CalculatingBoilingPoint',
  components: {CalculationInput, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        changeInBoilingPoint: null,
        boilingPoint: null,
      },
    };
  },
  computed: {
    molality() {
      return this.taskState.getValueBySymbol('molality').content;
    },
  },
};
</script>

<style scoped></style>
